export default {
  AccountSignUp: '/api/account/signup',
  AccountDetails: '/api/account/details',
  AccountUpdate: '/api/account',
  AccountContactUpdate: '/api/account/contact',
  AppAccountsContext: '/api/account/clientAccountContext',
  CartAdd: '/api/cart/add',
  CartBulkAdd: '/api/cart/bulk/add',
  CartBulkRemove: '/api/cart/bulk/remove',
  CartClear: '/api/cart/clear',
  CartGetAll: '/api/cart/getall',
  CartRemove: '/api/cart/remove',
  CartSubmit: '/api/subscriptions/checkoutSubscriptions',
  CartUpdate: '/api/cart/update',
  CheckVAT: '/api/general/checkvat',
  CmsResources: '/api/cms/resources/',
  Countries: '/api/general/countries',
  Currencies: '/api/general/currencies',
  GetCustomersFromTrimble: '/account/customers',
  GetUserRolesFromTrimble: '/account/role',
  InvoiceDownload: '/api/invoices',
  InvoiceGetAll: '/api/invoices',
  PaymentGetCCardHPP: '/api/payment/hpp/zuora',
  PaymentGetDDebitHPP: '/api/payment/hpp/zuora',
  PaymentGetPaid: '/api/payment/getpaid',
  PaymentSetMethod: '/api/payment/SetPaymentMethod',
  PaymentSetTwikeyDirectDebit: '/api/payment/twikey/SetDirectDebit',
  ProductsGetAll: '/api/products/catalog',
  TestConnection: '/api/general/testconnection',
  TrucksGet: '/api/subscriptions',
  // #Paccar-POC
  TrucksGetAll: '/api/subscriptions',
  TrucksPerService: '/api/trucks/perservice',
  TrucksPerServiceDetail: '/api/subscriptions',
  TrucksServices: '/api/trucks/services',
  CompanyService: '/api/company',
  FutureInvoiceOwner: '/api/account/futureInvoiceOwner',
  SetDefaultPaymentMethod: '/api/Payment/paymentmethod/setdefault',
  DeletePaymentMethod: '/api/payment',
  GetAllPaymentMethods: '/api/payment',
  GetDefaultPayment: '/api/Payment/payment-methods/default',
  ConfirmDefaultPaymentMethod: '/api/account/confirmDefaultPaymentMethod',
  SubscriptionPreview: '/api/orders/preview',
  CheckoutProgress: '/api/subscriptions/checkoutProgress',
  TruckSubscriptionsByVin: '/api/subscriptions/vin'
}
